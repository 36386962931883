import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ isCompression: false })

export const generalStore = defineStore('general', () => {
  const appSettings = ref(ls.get('appSettings') || null)
  const desktop = computed(() => screen.width >= 992)

  function getAppSettings () : void {
    axios.get(process.env.VUE_APP_API_URL + '/appSettings/buyersPortal').then((r) => {
      appSettings.value = r.data
      ls.set('appSettings', r.data)
    })
  }

  return { appSettings, desktop, getAppSettings }
})
