import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { authStore } from '@/stores/auth'
import { headerStore } from '@/stores/header'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/wachtwoord-vergeten',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/reset/wachtwoord',
    name: 'ActivatePassword',
    component: () => import(/* webpackChunkName: "activatePassword" */ '../views/ActivatePassword.vue')
  },
  {
    path: '/bouwnummers',
    name: 'BuildingSelector',
    component: () => import(/* webpackChunkName: "buildingSelector" */ '../views/BuildingSelector.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/modulaire-opties',
    name: 'Modular',
    component: () => import(/* webpackChunkName: "modular" */ '../views/Modular.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ruwbouw-opties',
    name: 'Carcass',
    component: () => import(/* webpackChunkName: "carcass" */ '../views/Carcass.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/afbouw-1-opties',
    name: 'Completion1',
    component: () => import(/* webpackChunkName: "completion1" */ '../views/Completion1.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/afbouw-2-opties',
    name: 'Completion2',
    component: () => import(/* webpackChunkName: "completion2" */ '../views/Completion2.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/afbouw-3-opties',
    name: 'Completion3',
    component: () => import(/* webpackChunkName: "completion3" */ '../views/Completion3.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/extra-opties',
    name: 'Extra',
    component: () => import(/* webpackChunkName: "extra" */ '../views/Extra.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/samenvatting',
    name: 'Summary',
    component: () => import(/* webpackChunkName: "summary" */ '../views/Summary.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nieuws/:newsId',
    name: 'NewsItem',
    component: () => import(/* webpackChunkName: "newsItem" */ '../views/NewsItem.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vragen',
    name: 'Questions',
    component: () => import(/* webpackChunkName: "questions" */ '../views/Questions.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vraag/toevoegen',
    name: 'QuestionCreate',
    component: () => import(/* webpackChunkName: "questionCreate" */ '../views/QuestionCreate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vraag/:questionId',
    name: 'QuestionShow',
    component: () => import(/* webpackChunkName: "questionShow" */ '../views/QuestionShow.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "service" */ '../views/Service.vue'),
    beforeEnter: () => {
      if (!headerStore().serviceEnabled) {
        return { name: 'Dashboard' }
      }
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bezwaar/toevoegen',
    name: 'ObjectionCreate',
    component: () => import(/* webpackChunkName: "objectionCreate" */ '../views/ObjectionCreate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bezwaar/:objectionId',
    name: 'ObjectionShow',
    component: () => import(/* webpackChunkName: "objectionShow" */ '../views/ObjectionShow.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/servicemelding/toevoegen',
    name: 'IssueCreate',
    component: () => import(/* webpackChunkName: "issueCreate" */ '../views/IssueCreate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/servicemelding/:issueId',
    name: 'IssueShow',
    component: () => import(/* webpackChunkName: "issueShow" */ '../views/IssueShow.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/service/overzicht',
    name: 'Global/Issues',
    component: () => import(/* webpackChunkName: "globalIssues" */ '../views/globalService/Issues.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/service/melding/:issueId',
    name: 'Global/IssueShow',
    component: () => import(/* webpackChunkName: "globalIssueShow" */ '../views/IssueShow.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/service/melding/toevoegen',
    name: 'Global/IssueCreate',
    component: () => import(/* webpackChunkName: "globalIssueCreate" */ '../views/IssueCreate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documenten',
    name: 'Documents',
    component: () => import(/* webpackChunkName: "documents" */ '../views/Documents.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/energie-vergelijker',
    name: 'EnergyComparison',
    component: () => import(/* webpackChunkName: "energyComparison" */ '../views/EnergyComparison.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  window.scrollTo(0, 0)

  if (to.meta.requiresAuth && !authStore().loggedIn) {
    return { name: 'Login', query: { redirect: to.fullPath } }
  }

  // Check if there is a redirect required after building selection.
  if (to.name === 'BuildingSelector' && from.query.redirect && !to.query.redirect) {
    return { name: 'BuildingSelector', query: { redirect: from.query.redirect } }
  }
})

export default router
